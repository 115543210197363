import VideoScrubber from "../VideoScrubber";
import Controls0 from "./ai-artist";
import Controls1 from "./hilbert";
import Controls2 from "./temple-photo-op";
import Controls3 from "./wind";
import Controls7 from "./photo-op";
import Controls9 from "./sorting";
import Controls12 from "./style-transfer";
import Controls21 from "./stylegan";
import Controls27 from "./pong4";
import Controls28 from "./bus-bunching";
import Controls31 from "./butterfly";
import Controls43 from "./ifs";
import Controls45 from "./life";
import Controls46 from "./graduation";
import Controls54 from "./maze-generation";
const controls: Map<string, () => JSX.Element> = new Map([
  ["ai-artist", Controls0],
["hilbert", Controls1],
["temple-photo-op", Controls2],
["wind", Controls3],
["death", VideoScrubber],
["metro", VideoScrubber],
["photo-op", Controls7],
["sorting", Controls9],
["estefan", VideoScrubber],
["style-transfer", Controls12],
["rams-horn", VideoScrubber],
["papa", VideoScrubber],
["cenote", VideoScrubber],
["stylegan", Controls21],
["grendel", VideoScrubber],
["pong4", Controls27],
["bus-bunching", Controls28],
["butterfly", Controls31],
["taijitu", VideoScrubber],
["studacc", VideoScrubber],
["stowaway", VideoScrubber],
["salt", VideoScrubber],
["owned", VideoScrubber],
["ifs", Controls43],
["life", Controls45],
["graduation", Controls46],
["pajama-gladiator", VideoScrubber],
["witchscat", VideoScrubber],
["maze-generation", Controls54],
["sheriff", VideoScrubber]
]);
export default controls;
