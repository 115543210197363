/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import { useMessaging } from "@footron/controls-client";
import {
  FormControl,
  NativeSelect,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormGroup, Slider
} from "@material-ui/core";
import { useCallback } from "react";

const containerStyle = css`
  padding: 16px;
  overflow-x: hidden;

  p {
    margin: 0 0 16px;
  }
`;

const names = [
[ 0, 'Dallan Hales Adamson' ],
[ 1, 'Luke Albin' ],
[ 2, 'Rylee May Albrecht' ],
[ 3, 'Dylan Barratt Allen' ],
[ 4, 'Sydney Allen Nielson' ],
[ 5, 'Kelly Camille Alvarado Plasencia' ],
[ 6, 'Arotoa Haetaautiatinu Anderson' ],
[ 7, 'Connor Stanley Anderson' ],
[ 8, 'Trevor Nielson Andrus' ],
[ 9, 'Kaylee Jo Anthony' ],
[ 10, 'Stanton Tannous Yow-Seng Anthony' ],
[ 11, 'Jacob Gallardo Armenta' ],
[ 12, 'Adam True Arnett' ],
[ 13, 'Jeffrey Allen Arnold' ],
[ 14, 'Emily Rose Asplund' ],
[ 15, 'Noor Shawkat Assi' ],
[ 16, 'Emma Katherine Ausman' ],
[ 17, 'Seungyeol Baek' ],
[ 18, 'James Elias Baker' ],
[ 19, 'Jared Loren Barker' ],
[ 20, 'Dallin Bradley Bartholomew' ],
[ 21, 'Spencer Dean Bartholomew' ],
[ 22, 'Lucy Marie Barton' ],
[ 23, 'Zachary James Roger Beach' ],
[ 24, 'Beric Yuuma Bearnson' ],
[ 25, 'Samuel David Bennion' ],
[ 26, 'Samuel Lowell Bennion' ],
[ 27, 'Samuel Matthias Bennion' ],
[ 28, 'Brennan Tag Bertola' ],
[ 29, 'Vigynesh Bhatt' ],
[ 30, 'Jared Hyrum Black' ],
[ 31, 'Andrew Joseph Blackman' ],
[ 32, 'Daniel Scott Blanchard' ],
[ 33, 'Gavin M Bolin' ],
[ 34, 'Ethan M Borsky' ],
[ 35, 'McKay Thomas Bowcut' ],
[ 36, 'Dallin Gary Bowie' ],
[ 37, 'Linsey Suella Bowie' ],
[ 38, 'Jacob Daniel Bowman' ],
[ 39, 'Omar Emmanuel Briceno-Nunez' ],
[ 40, 'Brian Charles Brown' ],
[ 41, 'Taylor Mark Brown' ],
[ 42, 'Derrick Lee Brundage' ],
[ 43, 'Gregory Allen Bullard' ],
[ 44, 'Alec Kent Burnett' ],
[ 45, 'Sarah Genieve Buss' ],
[ 46, 'Macee Martha Byers' ],
[ 47, 'Courtni Linae Byun' ],
[ 48, 'Kylie Joana Major Callaway' ],
[ 49, 'Darien Alexander Campbell' ],
[ 50, 'Jared Adan Canizales' ],
[ 51, 'Xuan Cao' ],
[ 52, 'Samuel Gregory Carlsen' ],
[ 53, 'Moisés AbinadÃ­ Carpio Lazo' ],
[ 54, 'Sarah Jean Kharaka Carson' ],
[ 55, 'Clayton Christian Chase' ],
[ 56, 'Spencer Jorden Chavez' ],
[ 57, 'Jonathan Reeve Chelius' ],
[ 58, 'Kai Chen' ],
[ 59, 'Brian Jun Xian Chi' ],
[ 60, 'Jordan Alan Chwalik' ],
[ 61, 'Cameron Ray Clark' ],
[ 62, 'Austin Paul Clarke' ],
[ 63, 'Aaron Michael Clayton' ],
[ 64, 'Kendall Matthew Clement' ],
[ 65, 'Tanner Reid Clements' ],
[ 66, 'Benjamin Tait Cookson' ],
[ 67, 'Minhoru Jushara Cotache Leon' ],
[ 68, 'Stephen Holmes Cowley' ],
[ 69, 'Bridger Reese Danby' ],
[ 70, 'Tanner Brokaw Day' ],
[ 71, 'Tyler Ross Dean' ],
[ 72, 'Hannah Isabell Decker' ],
[ 73, 'Walter DeGering' ],
[ 74, 'Stephen Aaron Derbidge' ],
[ 75, 'Bryan Jay Despain' ],
[ 76, 'Paige Irene Despain' ],
[ 77, 'Jared Evans Devenport' ],
[ 78, 'Brendan Joel Dewberry' ],
[ 79, 'Hoan Do' ],
[ 80, 'Brody Kurt Dobson' ],
[ 81, 'Jacob Heath Drake' ],
[ 82, 'Joshua David Draper' ],
[ 83, 'Tyler Cole Draper' ],
[ 84, 'Russell Joseph Dupaix' ],
[ 85, 'James Matthew Edwards' ],
[ 86, 'Joshua Jensen Egbert' ],
[ 87, 'Patrick Lloyd Elzinga' ],
[ 88, 'Nathan Yang Engel' ],
[ 89, 'Marcos Espino-Moreno' ],
[ 90, 'Miguel de Nazare do Espirito Santo' ],
[ 91, 'Joshua Justin Esplin' ],
[ 92, 'Nathan Earl Fastabend' ],
[ 93, 'Jordan David Ferrell' ],
[ 94, 'MacKenzie Kay Fisher' ],
[ 95, 'Benjamin Daniel Foutz' ],
[ 96, 'Jacob Lynn Frandsen' ],
[ 97, 'Seth Dalen Fredrickson' ],
[ 98, 'Quincey Martin Frewin' ],
[ 99, 'Rachel Fullerton' ],
[ 100, 'Mikael Christopher Gamett' ],
[ 101, 'Jacob Millett Gardiner' ],
[ 102, 'Adam Daniel Gardner' ],
[ 103, 'Kimball Wayne George' ],
[ 104, 'Benjamin Spencer Giles' ],
[ 105, 'Jose Humberto Giraldez Chavez' ],
[ 106, 'Devon Layne Gneiting' ],
[ 107, 'Milvia Goulding' ],
[ 108, 'Matthew John Gunson Jr.' ],
[ 109, 'Francis Imad Habash' ],
[ 110, 'Zachary Mark Hacking' ],
[ 111, 'Dallin Michael Hagman' ],
[ 112, 'Christian James Hall' ],
[ 113, 'Payton James Hall' ],
[ 114, 'Maxwell Alldredge Hamilton' ],
[ 115, 'Hunter Regan Hansen' ],
[ 116, 'Nathaniel Lee Hanson' ],
[ 117, 'Caleb Marshall Harding' ],
[ 118, 'Cal Hargis' ],
[ 119, 'Micah Moroni Harps' ],
[ 120, 'Emma Christine Harrington' ],
[ 121, 'Austin Lee Harris' ],
[ 122, 'Jacob Paul Hart' ],
[ 123, 'Andrew James Hartsfield' ],
[ 124, 'Jackson LeRoy Harwood' ],
[ 125, 'Bradley Dean Hatch' ],
[ 126, 'Paul Carlos Hathaway' ],
[ 127, 'Sydney Isabelle Hawkins' ],
[ 128, 'Michael James Hawks' ],
[ 129, 'Joshua David Hemmert' ],
[ 130, 'David Hernandez Martinez' ],
[ 131, 'Michael William Holland' ],
[ 132, 'Stephen R Hood' ],
[ 133, 'Alexis Lynn Hovorka' ],
[ 134, 'Zhengyang Huang' ],
[ 135, 'Pearl Kathleen Hulbert' ],
[ 136, 'Dallin Cole Hunter' ],
[ 137, 'Dallin James Hutchison' ],
[ 138, 'Sofia Grace Iannicelli' ],
[ 139, 'Brendan Raul Izu' ],
[ 140, 'Ian Brent Jackson' ],
[ 141, 'Benjamin Scott Jacobs' ],
[ 142, 'Charise Alexandria Hirschi Jardine' ],
[ 143, 'Gideon Glade Jardine' ],
[ 144, 'Abigail Jensen' ],
[ 145, 'Blake J Jensen' ],
[ 146, 'Keylan Hin-Lung Edward Jensen' ],
[ 147, 'Phineas Teschner Jensen' ],
[ 148, 'Timothy Rex Jensen' ],
[ 149, 'Cody Daniel Johns' ],
[ 150, 'Joseph Smith Johnson' ],
[ 151, 'Spencer Cannon Jorgensen' ],
[ 152, 'Chetan Joshi' ],
[ 153, 'Nathaniel Dart Judd' ],
[ 154, 'Thummim Jung' ],
[ 155, 'Chahyun Kang' ],
[ 156, 'Gabriella Chretienne Kang' ],
[ 157, 'Hanbich Kang' ],
[ 158, 'Tyler Carl Karren' ],
[ 159, 'Chance Hunter Kearl' ],
[ 160, 'Ethan Landon Kerr' ],
[ 161, 'Mahmoud Ibrahim Khalil' ],
[ 162, 'Taeyang Kim' ],
[ 163, 'Kevin Mathew Kinney' ],
[ 164, 'Logan Dennis Kitchen' ],
[ 165, 'Kaden Andrew Klein' ],
[ 166, 'Carson Kurt Koehle' ],
[ 167, 'Spencer Isaac Kraus' ],
[ 168, 'James Allan Krueger' ],
[ 169, 'Daria Mykolaivna Kukharuk' ],
[ 170, 'Jacob Graham Kullberg' ],
[ 171, 'Nathaniel Lampros' ],
[ 172, 'Justin Dean Lapicola' ],
[ 173, 'Derek Milton Larsen' ],
[ 174, 'Elizabeth Ann Larsen' ],
[ 175, 'Hunter Robert Larson' ],
[ 176, 'Kymberly Larson' ],
[ 177, 'Sean Arden Last' ],
[ 178, 'Elliot Jack Layton' ],
[ 179, 'Chin Hei Helaman Lee' ],
[ 180, 'Jonghyun Lee' ],
[ 181, 'Joshua Thomas Lee' ],
[ 182, 'Ryan Douglas Lee' ],
[ 183, 'Leo Taylor LeSueur' ],
[ 184, 'Grant Spencer Lewis' ],
[ 185, 'Zarin Platz Loosli' ],
[ 186, 'Jaden Quinn Lorenc' ],
[ 187, 'Christian Chantabury Luangrath' ],
[ 188, 'Colby John Lyman' ],
[ 189, 'Malcolm Grant MacGregor' ],
[ 190, 'Jordan PÅhaku  Mack' ],
[ 191, 'Michael Hunter Madsen' ],
[ 192, 'Blake Andrew Martin' ],
[ 193, 'Max Doyle Mason' ],
[ 194, 'Phillip Mark Mason' ],
[ 195, 'Andres Eduardo Matos' ],
[ 196, 'Gabriel Taylor Maxfield' ],
[ 197, 'Thomas James Maxwell' ],
[ 198, 'Bright Mazura' ],
[ 199, 'Justin Andrew McBride' ],
[ 200, 'Rose Clarice Filichia McClure' ],
[ 201, 'Tyson John McCulloch' ],
[ 202, 'Emily Eliza McDermott' ],
[ 203, 'Jason Michael McDonald' ],
[ 204, 'Isaac Hatch McGill' ],
[ 205, 'Nathan Charles McGrew' ],
[ 206, 'Jacob Scott McKenzie' ],
[ 207, 'Ryan Michael Meyer' ],
[ 208, 'Ethan Lee Michaelis' ],
[ 209, 'Caelen Dale Miller' ],
[ 210, 'Daniel Frank Millward' ],
[ 211, 'Spencer Gordon Moffat' ],
[ 212, 'Andres Felipe Molina Ospina' ],
[ 213, 'Jared Mark Montierth' ],
[ 214, 'Gage Bentley Moore' ],
[ 215, 'Victoria On-Kei Moreno' ],
[ 216, 'Jacob Gordon Morrey' ],
[ 217, 'Melissa Amber Morris' ],
[ 218, 'Andrew Clendon Moss' ],
[ 219, 'Jeremy Russell Mumford' ],
[ 220, 'Conner Charles Murray' ],
[ 221, 'Abel Ware Nelson' ],
[ 222, 'Hannah Kristine Nelson' ],
[ 223, 'Peter Hinckley Neubert' ],
[ 224, 'Alexander Charles Neville' ],
[ 225, 'Jonas Christian Nielsen' ],
[ 226, 'Noelle Erika Nielson' ],
[ 227, 'Michael Brian Norris' ],
[ 228, 'Samuel Anders Olausson' ],
[ 229, 'Justin Paul Olcott' ],
[ 230, 'Suguru Onda' ],
[ 231, 'Dillon Jeremy Ormond' ],
[ 232, 'Nathan Rhead Owen' ],
[ 233, 'Christopher Braxton Owens' ],
[ 234, 'Andrew Robert Palmer' ],
[ 235, 'Jooyeon Park' ],
[ 236, 'Patrick Jefferson Parker' ],
[ 237, 'Elizabeth Grace Parkinson' ],
[ 238, 'David Matthew Parrott' ],
[ 239, 'Eden Khan Paupulaire' ],
[ 240, 'Matthew Benjamin Peart' ],
[ 241, 'Sara Marie Peel' ],
[ 242, 'Michael Perkins' ],
[ 243, 'Rachel Marie Perrier' ],
[ 244, 'Gabriel Michael Perry' ],
[ 245, 'Hana Pertab' ],
[ 246, 'Andrew Birch Peterson' ],
[ 247, 'Isaac William Peterson' ],
[ 248, 'Jackson Lee Peterson' ],
[ 249, 'Joseph Alexander Stuart Peterson' ],
[ 250, 'Bryce McKay Pierson' ],
[ 251, 'Justin Warren Ponton' ],
[ 252, 'Timothy James Pope' ],
[ 253, 'William Teague Porter' ],
[ 254, 'Joseph Nathaniel Poulson' ],
[ 255, 'Matthew Edward Powley' ],
[ 256, 'Noah Jacob Pratt' ],
[ 257, 'McKayla Noelle Pressler' ],
[ 258, 'Garrett Lynn Price' ],
[ 259, 'Calvin Benjamin Pugmire' ],
[ 260, 'Alex Qian' ],
[ 261, 'Delaney Margaret Reed' ],
[ 262, 'Jared Ryan Renberg' ],
[ 263, 'Kathryn Dee Rencher' ],
[ 264, 'Nathan Charles Rice' ],
[ 265, 'Charles Coulson Rich' ],
[ 266, 'Michael Lynn Richards' ],
[ 267, 'Jacob Richmond' ],
[ 268, 'Beckett Kimber Ricks' ],
[ 269, 'Benjamin David Ricks' ],
[ 270, 'Kyle Jeffrey Rogers' ],
[ 271, 'Riley Grant Ross' ],
[ 272, 'Jackson Roubidoux' ],
[ 273, 'Adam Jared Rounsville' ],
[ 274, 'Jaxson Waters Ruff' ],
[ 275, 'Cole Brian Russon' ],
[ 276, 'Garrett Neil Sapp' ],
[ 277, 'Nicole Sue Sapp' ],
[ 278, 'Vijay Kiran Satyam' ],
[ 279, 'Anson Raymond Savage' ],
[ 280, 'Jakob Neal Schilling' ],
[ 281, 'Benjamin Isaac Schulzke' ],
[ 282, 'Rachel ArDella Scott' ],
[ 283, 'Samuel Todd Seager' ],
[ 284, 'Jackson Jay Searle' ],
[ 285, 'Peter Andrew Seely' ],
[ 286, 'Brady James Sillito' ],
[ 287, 'Elliot Alexandre Silveira' ],
[ 288, 'Ethan Holland Simmons' ],
[ 289, 'Riley Jacob Sinema' ],
[ 290, 'Nathan Tanner Sirrine' ],
[ 291, 'Scott David Slinn' ],
[ 292, 'Brennan Jacob Smeltzer' ],
[ 293, 'Mark John Smiley' ],
[ 294, 'Jason Paul Smith' ],
[ 295, 'Juliana Celeste Smith' ],
[ 296, 'Kaleb Jarom Smith' ],
[ 297, 'Maxwell Steven Smith' ],
[ 298, 'Hyrum Gary Sorensen' ],
[ 299, 'Ty Scott Sorensen' ],
[ 300, 'Lawry Eliot Sorenson' ],
[ 301, 'Brody Jones Spencer' ],
[ 302, 'Abigail Joy Spigarelli' ],
[ 303, 'Gavin McCrae Steck' ],
[ 304, 'Isabella Dupré Cadena Stephens' ],
[ 305, 'Josiah Jaron Stephens' ],
[ 306, 'Michal Anne Gibson' ],
[ 307, 'John Michael Stout' ],
[ 308, 'Ren Strauss' ],
[ 309, 'John Casey Strong' ],
[ 310, 'Kenton Carl Strong' ],
[ 311, 'Tanner Jack Sundwall' ],
[ 312, 'Logan Daniel Tailleur' ],
[ 313, 'Ashtyn Maria Taylor' ],
[ 314, 'Benjamin Ray Taylor' ],
[ 315, 'Camren Michael Taylor' ],
[ 316, 'Joshua Brubaker Taylor' ],
[ 317, 'Nicholas Dean Taylor' ],
[ 318, 'Amy Fong Techavimol' ],
[ 319, 'Emily Ting Teng' ],
[ 320, 'Haile Diana Terry' ],
[ 321, 'Marc Adam Terry' ],
[ 322, 'Jacob Alexander Thomsen' ],
[ 323, 'Alison Anne Rampton' ],
[ 324, 'Logan Blake Thurman' ],
[ 325, 'Josh Caleb Andrew Tidwell' ],
[ 326, 'Damon Wesley Tingey' ],
[ 327, 'Benjamin Porter Tolley' ],
[ 328, 'Gideon Matthew Tonkinson' ],
[ 329, 'Jonathan Brett Tullis' ],
[ 330, 'Nikelle Charity Turner' ],
[ 331, 'Stephen Dean Tveten' ],
[ 332, 'Zachary Ethan Tyler' ],
[ 333, 'Ernesto Antonio Valencia Arevalo' ],
[ 334, 'Troy Chandler Van Meter' ],
[ 335, 'Talden J. VanderLouw' ],
[ 336, 'Dallin Jacob Vetter' ],
[ 337, 'Conner Joseph Vigil' ],
[ 338, 'Ethan Taylor Walch' ],
[ 339, 'Cassidy Marie Waldrip' ],
[ 340, 'Nathan Douglas Walker' ],
[ 341, 'Zachary Tyler Wallace' ],
[ 342, 'Yiqi Wang' ],
[ 343, 'Austin Rex Warnick' ],
[ 344, 'Chase Martin Westhoff' ],
[ 345, 'Jack David Wheatley' ],
[ 346, 'Brianna Elaine White' ],
[ 347, 'Connor Hansen Widtfeldt' ],
[ 348, 'Quincy Gayle Wilcox' ],
[ 349, 'Connor Randall Wilhelm' ],
[ 350, 'Andrew Martin Wilson' ],
[ 351, 'Bryton S. Wilson' ],
[ 352, 'Jared D Wilson' ],
[ 353, 'McKynlee Wilson Engel' ],
[ 354, 'Lexie Elizabeth Wilsted' ],
[ 355, 'Andrew K Winsborrow' ],
[ 356, 'Harrison Q Winslow' ],
[ 357, 'Riley Amanda Woolf' ],
[ 358, 'Eian Tred Wright' ],
[ 359, 'Tarun Kumar Yadav' ],
[ 360, 'Zachary Douglas Yancey' ],
[ 361, 'Henry Mathewson Young' ],
[ 362, 'Jinxin Yu' ],
[ 363, 'Jingyu Zhao' ],
[ 364, 'Zhuokang Zhao' ],
[ 365, 'Jacob Powell Zinn' ],
[ 366, 'Benjamin Nathan Zobrist' ],
]

const ControlsComponent = (): JSX.Element => {
  const [name, setName] = useState<string>("");

  const { sendMessage } = useMessaging<any>();

  const handleNameChange = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    const name = event.target.value;
    setName(name);
    sendMessage(name)
  };

  return (
    <div css={containerStyle}>
      <FormGroup>
        <p>
          <b>Pick your name from the list to see it on the big screen! (Your name will show for 60 seconds)</b>
        </p>
        <FormControl>
          <InputLabel htmlFor="name-native-helper">Name</InputLabel>
          <NativeSelect
            inputProps={{
              name: 'Name',
              id: 'name-native-helper',
            }}
            id="demo-simple-select"
            value={name}
            name="Name"
            onChange={handleNameChange}
          >
            <option value="all">All names</option>
            {
              names.map((name) => <option key={"id"} value={name[0]}>{name[1]}</option>)
            }
          </NativeSelect>
        </FormControl>
      </FormGroup>
    </div>
  );
};

export default ControlsComponent;
